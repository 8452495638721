$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
/*@import "~bootstrap-sass/assets/stylesheets/bootstrap";*/

@import "variables";
@import "shared/base";

/*
html {
  background: url("https://source.unsplash.com/2560x1440/?forest,mountain") center no-repeat fixed;
}
*/

/*body {
  background: rgba(#000000, 0.6);
  padding: 0px 20px 20px 20px;
  min-height: 100vh;
}*/

/*.navbar-brand {
  font-size: 1.5em;
  img {
    display: inline-block;
    width: 50px;
  }
}*/

/*#root {
  h2 {
    margin-top: 0px;
  }

  h4 {
    margin-top: 20px;
  }
}*/
.MuiSvgIcon-root {
  font-size: 1em;
}
.btn-action {
  outline: none;
  border: none;
}

$primary-color: #aaf9a7;
$secondary-color: #0beb46;

@import '../../../node_modules/react-notifications-component/dist/scss/containers';
@import '../../../node_modules/react-notifications-component/dist/scss/notification';
@import '../../../node_modules/react-notifications-component/dist/scss/variables';
@import '../../../node_modules/react-notifications-component/dist/scss/types';
@import '../../../node_modules/loaders.css/src/animations/ball-beat';

.loader-hidden {
  display: none;
}
@import "main-window";
@import "carousel";
@import "upload-modal";
@import "leaflet-control-locate";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw !important;
  height: 100vh !important;
  padding: 0px 0px 0px 0px !important;
}

@font-face {
  font-family: "casino_handregular";
  src: url("../fonts/casino_hand-webfont.eot");
  src: url("../fonts/casino_hand-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/casino_hand-webfont.woff") format("woff"), url("../fonts/casino_hand-webfont.ttf") format("truetype"),
    url("../fonts/casino_hand-webfont.svg#casino_handregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.MuiBottomNavigationAction-root {
  color: #ffffff !important;
  font-size: 12px !important;
}
.MuiBottomNavigationAction-root:hover {
  color: #ffffff !important;
  padding-top: 6px;
  border-radius: 5px;
  height: 52px;
  margin-top: 15px;
  background-color: #2979ff;
}
.MuiBottomNavigationAction-label.Mui-selected {
  color: #ffffff !important;
  font-size: 12px !important;
}
.MuiBottomNavigationAction-root.Mui-selected {
  color: #ffffff !important;
  padding-top: 6px;
  border-radius: 5px;
  height: 52px;
  margin-top: 15px;
  background-color: rgba(255, 255, 255, 0.2);
}
.MuiTabScrollButton-root.Mui-disabled {
  opacity: 0;
  width: 0px;
}

.MuiListItem-secondaryAction {
  padding-right: 16px !important;
}
.MuiTabs-scrollable {
  overflow-x: scroll;
  max-width: 35vw;
  vertical-align: middle;
}


#nexuAnim {
  position: relative;

  overflow: hidden;
}
#nexuAnim::before {
  content: "";
  position: absolute;
  background: #04acff;
  width: 100%;
  bottom: 0;
  animation: wipe 5s cubic-bezier(0.2, 0.6, 0.8, 0.4) forwards infinite;
}
@keyframes wipe {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

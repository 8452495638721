.leaflet-control-styleeditor .leaflet-control-styleeditor-interior {
    background-image: url('../images/styleEditorControlIcon.svg');
    background-position: 50% 50%;
    background-size: 50%;
}
.leaflet-control-styleeditor-interior:hover {
    background-color: #07C217;
}
.leaflet-control-styleeditor .leaflet-control-styleeditor-cancel {
    position: absolute;
    top: 0;
    right: 100%;
    background-color: #919187;
    -webkit-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
    font-size: 12px;
    line-height: 30px;
    height: 30px;
    color: white;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
}
.leaflet-control-styleeditor .leaflet-control-styleeditor-cancel:hover {
    background-color: #a0a098;
    cursor: pointer;
}
.enabled {
    background-color: #b0de5c;
}
.enabled:hover {
    background-color: #07C217;
}
.leaflet-styleeditor {
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.65);
    height: 100%;
    right: 0;
    background-color: white;
    width: 0;
    position: absolute;
    margin: 0px;
    overflow: hidden;
    -webkit-transition-property: -webkit-transform, width;
    transition-property: transform, width;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -ms-transform: translatex(0);
    -webkit-transform: translatex(0);
    transform: translatex(0);
    z-index: 1000;
}
.editor-enabled {
   width: 200px;
}
.leaflet-styleeditor-hidden {
    display: none;
}
.leaflet-styleeditor-fill-horizontal{
    display: flex;
}
.leaflet-styleeditor-fill-vertical{
    flex-direction: column;
    display: flex;
}
.leaflet-styleeditor-fill {
    flex: 1;
    min-width: 1px;
    min-height: 1px;
}
.leaflet-styleeditor-header {
    position: relative;
    height: 40px;
    background-color: #36c2f6;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.leaflet-styleeditor-interior {
    padding: 20px;
    overflow: auto;
    position: relative;
    right: 0px;
    height: calc(100% - 40px);
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
}
.leaflet-styleeditor-interior-geometry {
    margin-bottom: 24px;
}
.leaflet-styleeditor-label {
    width: 100px;
    display: block;
    font-size: medium;
}
.leaflet-styleeditor-input-span {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 0.8em;
}
input.leaflet-styleeditor-input {
    height: 30px;
    background: #fff;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    width: 150px;
}
textarea.leaflet-styleeditor-input {
    width: 100%;
}
.leaflet-styleeditor-select {
    position: relative;
    height: 32px;
    margin-bottom: 5px;
    padding-left: 10px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;
    width: 150px;
    overflow: hidden;
    padding: 0;
}
.leaflet-styleeditor-select-option-wrapper {
    position: absolute;
    list-style: none;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    top: 80px;
    bottom: 20px;
}
.leaflet-styleeditor-select-option {
    display: inline-block;
    height: 30px;
    width: 28px;
    padding: 4px;
    cursor: pointer;
    position: relative;
}
.leaflet-styleeditor-select-image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: visible;
    width: 100%;
    height: 100%;
}
.leaflet-styleeditor-select-image-defaultmarker {
    top: 11px;
}
.leaflet-styleeditor-select-image {
    position: relative;
    padding: 0;
    margin: 0;
}
.leaflet-styleeditor-button {
    border: 0px;
    display: inline-block;
    padding: 10px 20px;
    background: #36c2f6;
    height: 40px;
    margin-left: 0px;
    -moz-transition: background-color .25s ease-in-out;
    -webkit-transition: background-color .25s ease-in-out;
    -o-transition: background-color .25s ease-in-out;
    -ms-transition: background-color .25s ease-in-out;
    transition: background-color .25s ease-in-out;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
    cursor: pointer;
}
.styleeditor-nextBtn {
    float: right;
    margin-right: 0px;
    background-image: url('../images/styleEditorIcon.png');
    background-position: 10px -31px;
}
.leaflet-styleeditor-button:hover {
    background-color: #36d9f6;
}
.leaflet-styleeditor-button:active {
    background-color: #36d9f6;
}
.leaflet-styleeditor-colorpicker {
    width: 160px;
    display: inline-block;
}
.leaflet-styleeditor-color {
    height: 35px;
    width: 30px;
    float: left;
    border: 1px solid white;
    cursor: pointer;
}
.leaflet-styleeditor-color:hover {
    border:1px solid black;
}
.leaflet-styleeditor-sizeicon {
    background-image: url('../images/marker-icon-2x.png');
    background-repeat: no-repeat;
    float: left;
    margin-right: 15px;
    border: 1px solid white;
    cursor: pointer;
}
.leaflet-styleeditor-sizeicon:hover {
    border: 1px solid black;
}
.sizeicon-small {
    background-size: 20px 33px;
    width: 22px;
    height: 33px;
}
.sizeicon-medium {
    background-size: 25px 41px;
    width:28px;
    height:41px;
}
.sizeicon-large {
    background-size: 30px 49px;
    width:32px;
    height: 49px;
}
.leaflet-styleeditor-tooltip-wrapper {
    position: absolute;
    text-align: center;
    bottom: 10%;
    width: 100%;
    z-index: 1000;
}
.leaflet-styleeditor-tooltip {
    background-color: rgba(68, 68, 68, 0.2);
    border: 3px solid rgba(68, 68, 68, 0.7);
    border-radius: 5px;
    display: inline-block;
    font: 20px/1"Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 10px;
    position: relative;
    whitespace: no-wrap;
}
.leaflet-styleeditor-stroke {
    height: 20px;
    width: 150px;
    background-repeat: no-repeat;
    border: 1px solid white;
    background-image:  url('../images/styleEditorIcon.png');
    cursor: pointer;
}
.leaflet-styleeditor-stroke:hover {
    border: 1px solid black;
}

.leaflet-styleeditor-marker-s {
    padding-top: 3px;
    font-size: 8px;
    height: 50px;
    width: 20px;
}
.leaflet-styleeditor-marker-m {
    padding-top: 5px;
    font-size: 12px;
    height: 70px;
    width: 30px;
}
.leaflet-styleeditor-marker-l {
    padding-top: 9px;
    font-size: 16px;
    height: 90px;
    width: 35px;
}

.leaflet-styleeditor-marker {
    text-align: center;
    color: white;
    display: flex;
    background-repeat: no-repeat;
}

.leaflet-styleeditor-maki-marker-icon {
    width: 100%;
    height: 25%;
}
.leaflet-styleeditor-marker-selected {
    background-clip: padding-box;
    background-color: rgba(254,87,161,0.1);
    border: 4px dashed rgba(254,87,161,0.6);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    box-sizing: content-box;
    left: -4px;
    top: -4px;
}

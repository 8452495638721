// Variables
$main-color: #FFFFFF;
$first-color: #8484DE;
$secondary-color: darken(#888888, 10%);
$main-font-size: 14px;

$green: #7FBA00;
$yellow: #FCD116;
$red: #E81123;
$blueActive: #00AFF0;
$blue: #8484AA;

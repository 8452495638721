.main-window {
	padding-top: 80px;
	font-size: 1.75em;

	@media screen and (max-width: 767px) {
		padding-top: 40px;

		.pull-left, .pull-right {
			width: 100%;
			text-align: center;
		}

		.pull-right {
			margin-top: 20px;
		}
	}

	.btn-action {
		$height: 60px;
		height: $height;
		width: $height;
		margin: 20px 30px 0px 0px;
		line-height: $height;
		text-align: center;
		border-radius: 50%;
		border: solid 2px $main-color;
		cursor: pointer;
		transition-duration: 0.25s;
		background-color: transparent;

		&:hover {
			background-color: rgba($first-color, 0.2);
		}
	}

	.txt-clientId {
		height: 40px;
		margin: 40px auto 0px 10px;
		color: $main-color;
		font-size: 0.9em;
		background-color: transparent;
		border: none;
		border-bottom: solid 1px $main-color;
		@include input-placeholder(rgba($main-color, 0.8));
	}

}
.pg-viewer-wrapper{
	min-width: 300px;
	min-height: 300px;
	text-align: center;
	vertical-align: middle;
	padding: 10px;
	margin: auto;
	overflow: auto;
	z-index: 10;
	scrollbar-color: #8484de;
	scrollbar-width: thin;
}
.pg-viewer-wrapper::-webkit-scrollbar {
	width: 5px;
};
.pg-viewer-wrapper::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
};
.pg-viewer-wrapper::-webkit-scrollbar-thumb {
	background: #b5b5f8;
	border-radius: 10px;
};
.pg-viewer-wrapper::-webkit-scrollbar-thumb:hover {
	background: #8080f2;
};
